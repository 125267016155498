/* Xplan resets */
input[type='checkbox'],
input[type='color'],
input[type='date'],
input[type='datetime-local'],
input[type='file'],
input[type='hidden'],
input[type='image'],
input[type='time'],
input[type='week'] {
    margin: 0;
}
button {
    min-width: unset;
}
#pagecontent:has(.iff-root) {
    margin: 0;
}

/* IDS overrides */
.iress-button[custom-route] > [data-wrapper-children='true'] {
    display: contents;
}
.iress-form__error-summary {
    margin-block-end: var(--iress-g-spacing-md);
}
.iress-input[readonly='true'] {
    --iress-border-color: transparent;
    --iress-background-color: transparent;
    --iress-padding-x: 0px;
}
.iress-input textarea {
    resize: block;
}

/* Generic styles */
hr {
    border: none;
    border-block-end: 1px solid var(--iress-g-border-color, var(--iress-default-border-color));
}
