.ag-pension-switch {
    --ag-header-background-color: var(--ukw-grey-extra-light);
    padding: 0 var(--iress-a-horizontal-spacing-md);
}

.ag-pension-switch .ag-header {
    border-bottom: none;
}

.ag-pension-switch .ag-body-viewport {
    background-color: var(--ukw-grey-extra-light);
}

.ag-pension-switch .ag-cell {
    --ag-cell-font-size: var(--iress-g-font-size-rem);
    --ag-cell-vertical-padding: var(--iress-a-vertical-spacing-xs);
    display: flex;
    align-items: center;
}

/*https://www.ag-grid.com/javascript-data-grid/grid-size/*/
.ag-center-cols-viewport {
    min-height: unset !important;
}

.ag-row-hover {
    --ag-row-hover-color: var(--ukw-white);
}
