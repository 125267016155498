.plan-selection-table {
    --iress-background-color: transparent !important;
    --iress-header-background-color: transparent !important;
    --iress-row-even-background-color: transparent !important;
    --iress-row-odd-background-color: transparent !important;
}

.fa-info-square {
    margin-top: 2px;
}

.info {
    font-weight: 500 !important;
    color: var(--iress-heading-color, initial) !important;
}

.error {
    font-weight: 500 !important;
}
