.table {
    --iress-g-heading-font-weight: bold;
    --iress-button-padding-y: 0;

    .success {
        color: var(--iress-g-success-color);
    }

    .danger {
        color: var(--iress-g-danger-color);
    }
}
