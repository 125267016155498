/*------------------------------------------- Iress Wealth theme ----------------------------------------

This will apply overrides to the Iress light themes only; client themes should not be overridden. See
Figma for details - https://www.figma.com/file/eRGcYlMayTUOL7kFXY00nf/Colours-(Wealth-UK-theme-light)

------------------------------------------------------------------------------------------------------ */

@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@500&display=swap');

/* ------------------ Theme tokens ------------------ */
.iress-beta-theme-light.uk-wealth-theme,
.iress-beta-theme-light .uk-wealth-theme,
.iress-theme-light.uk-wealth-theme,
.iress-theme-light .uk-wealth-theme {
    /* UK Wealth colour palette from https://www.figma.com/file/eRGcYlMayTUOL7kFXY00nf/Colours-(Wealth-UK-theme-light)?node-id=1821%3A2 */
    --ukw-primary: hsl(254deg 66% 53%);
    --ukw-primary-tint: hsl(
        252deg 60% 95%
    ); /* equivalent to hsla(254deg 66% 53% / 0.1) on white - original value from Figma */
    --ukw-black: hsl(218deg 24% 27%);
    --ukw-grey-dark: hsl(216deg 9% 42%);
    --ukw-grey-light: hsl(217deg 16% 84%);
    --ukw-grey-extra-light: hsl(216deg 33% 97%);
    --ukw-white: hsl(0deg 100% 100%);
    --ukw-red: hsla(4deg 94% 41%);

    /* Size / spacing tokens */
    --iress-g-font-size-rem: 14px;
    --iress-g-spacing-rem: 16px;
    --iress-g-spacing-ratio-xs: 0.25;
    --iress-g-spacing-ratio-sm: 0.5;
    --iress-g-spacing-ratio-md: 1.25;
    --iress-g-spacing-ratio-lg: 1.75;
    --iress-g-spacing-ratio-xl: 3;
    --iress-g-spacing-xs: 4px;
    --iress-g-spacing-sm: 8px;
    --iress-g-spacing-md: 16px;
    --iress-g-spacing-lg: 24px;
    --iress-g-spacing-xl: 28px;
    --iress-a-vertical-spacing-xl: var(--iress-g-spacing-xl);
    --iress-a-vertical-spacing-lg: var(--iress-g-spacing-lg);
    --iress-a-vertical-spacing-md: 20px;
    --iress-a-vertical-spacing-sm: var(--iress-g-spacing-sm);
    --iress-a-vertical-spacing-xs: var(--iress-g-spacing-xs);
    --iress-a-horizontal-spacing-xl: var(--iress-g-spacing-xl);
    --iress-a-horizontal-spacing-lg: var(--iress-g-spacing-lg);
    --iress-a-horizontal-spacing-md: var(--iress-g-spacing-md);
    --iress-a-horizontal-spacing-sm: var(--iress-g-spacing-sm);
    --iress-a-horizontal-spacing-xs: var(--iress-g-spacing-xs);

    /* Colours */
    --iress-g-primary-color: var(--ukw-primary);
    --iress-g-primary-hover-color: hsl(254deg 66% 30%);
    --iress-g-danger-color: var(--ukw-red);
    --iress-g-border-color: var(--ukw-grey-light);
    --iress-g-background-color: var(--ukw-grey-extra-light);

    /* Typography */
    --iress-g-font-size-xxxxl: calc(var(--iress-g-font-size-rem) * 3.5);
    --iress-g-font-size-xxxl: calc(var(--iress-g-font-size-rem) * 2.5);
    --iress-g-font-size-xxl: calc(var(--iress-g-font-size-rem) * 2);
    --iress-g-font-size-xl: calc(var(--iress-g-font-size-rem) * 1.75);
    --iress-g-font-size-lg: calc(var(--iress-g-font-size-rem) * 1.5);
    --iress-g-font-size-md: calc(var(--iress-g-font-size-rem) * 1.375);
    --iress-g-font-size-sm: calc(var(--iress-g-font-size-rem) * 1.25);
    --iress-g-font-size-xs: calc(var(--iress-g-font-size-rem) * 1.125);
    --iress-g-font-size-xxs: var(--iress-g-font-size-rem);
    --iress-g-font-size-xxxs: calc(var(--iress-g-font-size-rem) * 0.875);
    --iress-g-text-color: var(--ukw-black);
    --iress-g-muted-text-color: var(--ukw-grey-dark);
    --iress-g-link-text-color: var(--iress-g-text-color);
    --iress-g-heading-font-weight: 300;
    --iress-a-heading-margin-bottom: 300;
    --iress-text-heading-1-font-size: 28px;
    --iress-text-heading-2-font-size: 18px;
    --iress-text-heading-1-font-weight: 500;

    /* Forms */
    --iress-a-label-text-color: var(--ukw-black);
    --iress-a-form-background-color: var(--ukw-white);
    --iress-checkbox-spacing-right: var(--iress-a-horizontal-spacing-sm);
    --iress-radio-spacing-right: var(--iress-a-horizontal-spacing-sm);
    --iress-label-required-text-color: var(--ukw-black);

    /* Interaction states */
    --iress-a-active-background-color: hsl(218deg 24% 27%);
    --iress-a-active-border-color: hsl(218deg 24% 27%);

    /* Defaults */
    --iress-input-min-height: calc(2.5 * var(--iress-g-font-size-rem));
    --iress-button-min-height: calc(2.5 * var(--iress-g-font-size-rem));

    /* Buttons */
    --iress-button-primary-background-color: var(--iress-g-primary-color);
    --iress-button-primary-border-color: var(--iress-g-primary-color);
    --iress-button-primary-hover-background-color: var(--iress-g-primary-hover-color);
    --iress-button-primary-hover-border-color: var(--iress-g-primary-hover-color);
    --iress-button-secondary-border-color: var(--iress-g-primary-color);
    --iress-button-secondary-text-color: var(--iress-g-primary-color);
    --iress-button-secondary-hover-border-color: var(--iress-g-primary-hover-color);
    --iress-button-secondary-hover-text-color: var(--iress-g-primary-hover-color);
    --iress-button-secondary-active-border-color: var(--iress-g-primary-hover-color);
    --iress-button-secondary-active-text-color: var(--iress-g-primary-hover-color);
    --iress-button-link-text-color: var(--iress-g-link-text-color);

    /* Checkboxes */
    --iress-checkbox-checked-background-color: var(--iress-a-active-background-color);
    --iress-checkbox-checked-border-color: var(--iress-a-active-background-color);

    /* Menu items */
    --iress-menu-item-selected-font-weight: bold;
    --iress-menu-item-selected-text-color: var(--iress-g-primary-color);
    --iress-menu-item-padding-x: 0px;
    --iress-menu-item-padding-y: calc(var(--iress-a-vertical-spacing-md) / 2);

    /* Tabs */
    --iress-tab-button-active-text-color: var(--iress-g-primary-color);
    --iress-tab-button-active-font-weight: 400;

    /* Expander */
    --iress-expander-activator-background-color: var(--ukw-grey-extra-light);

    font-size: var(--iress-g-font-size-rem, var(--iress-default-font-size--standard));
}

/* ------------------ Component overrides ------------------ */

/* Menu */
.iress-beta-theme-light.uk-wealth-theme .iress-menu-item--selected,
.iress-beta-theme-light .uk-wealth-theme .iress-menu-item--selected,
.iress-theme-light.uk-wealth-theme .iress-menu-item--selected,
.iress-theme-light .uk-wealth-theme .iress-menu-item--selected {
    --iress-mark-color: transparent;
    --iress-menu-item-text-color: var(--iress-g-primary-color);
    --iress-button-font-weight: 700;
    --iress-default-border-width--lg: 0;
}
.iress-beta-theme-light.uk-wealth-theme .iress-menu-item--divider,
.iress-beta-theme-light .uk-wealth-theme .iress-menu-item--divider,
.iress-theme-light.uk-wealth-theme .iress-menu-item--divider,
.iress-theme-light .uk-wealth-theme .iress-menu-item--divider {
    --iress-divider-border-width: 0;

    position: relative;
    margin-block-end: calc(2 * var(--iress-button-padding-y));
}
.iress-beta-theme-light.uk-wealth-theme .iress-menu-item--divider::after,
.iress-beta-theme-light .uk-wealth-theme .iress-menu-item--divider::after,
.iress-theme-light.uk-wealth-theme .iress-menu-item--divider::after,
.iress-theme-light .uk-wealth-theme .iress-menu-item--divider::after {
    content: '';
    width: calc(100% - (2 * var(--iress-menu-item-padding-x)));
    border-block-end: 1px solid var(--iress-divider-border-color);
    position: absolute;
    inset-block-end: calc(-1 * var(--iress-menu-item-padding-y));
    inset-inline-start: var(--iress-button-padding-x);
}
.iress-beta-theme-light.uk-wealth-theme .iress-text > h1,
.iress-beta-theme-light .uk-wealth-theme .iress-text > h1,
.iress-theme-light.uk-wealth-theme .iress-text > h1,
.iress-theme-light .uk-wealth-theme .iress-text > h1 {
    font-family: 'Ubuntu', 'Roboto', sans-serif;
}

/* Table */
.iress-beta-theme-light.uk-wealth-theme,
.iress-beta-theme-light .uk-wealth-theme,
.iress-theme-light.uk-wealth-theme,
.iress-theme-light .uk-wealth-theme {
    --iress-table-header-background-color: transparent;
    --iress-table-row-border-radius: 4px;
}
